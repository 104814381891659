// Log the value of REACT_APP_BRANCH
console.log("REACT_APP_BRANCH:", process.env.REACT_APP_BRANCH);

// Check the value of REACT_APP_BRANCH and select the appropriate key
let stripeKey;
let weightage;
if (process.env.REACT_APP_BRANCH === 'developer') {
  // Use developer key
  stripeKey = "pk_test_51MJh15A4DcRuO9tw1F1ZDOWnIJQVLeySPmCSeRayxgTId9kaR91gjjaicqmYE2iYR81SU2mprZnlsp9C4fgMu5OA00KqOT39tg";
  weightage = true;
  
} else if(process.env.REACT_APP_BRANCH === 'main') {
  // Use production key or any other default key
  stripeKey = "pk_live_51MJh15A4DcRuO9twMV24Yus30d8eDBNOm4e5dOrFWr24c0SX8vZaVS50LHVToqK9q98y3n8KuylDVVQubJPQPEvC00ifcpDpY5";
  weightage=false
  
}
else{
    stripeKey = "pk_live_51MJh15A4DcRuO9twMV24Yus30d8eDBNOm4e5dOrFWr24c0SX8vZaVS50LHVToqK9q98y3n8KuylDVVQubJPQPEvC00ifcpDpY5";
    weightage=false
}

// Export the selected stripeKey
export  {stripeKey ,weightage};
